import {useEffect} from "react";
import "../styles/Search.css";
import logo from "../assets/UAlogo.svg";
import SearchableSelect from "../components/SearchableSelect";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {LangContext} from "../Context/lanContext";
import CountryDropdown from "../components/CountryDropdown";

export const Search = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	const {changeLanguage} = useContext(LangContext);

	const {lang} = useParams();

	useEffect(() => {
		if (lang !== undefined && ["en", "fr"].includes(lang)) {
			changeLanguage(lang);
		} else if (lang === undefined) {
			changeLanguage("en");
		} else {
			navigate("/notfound");
		}
	}, []);

	const handleCountryChange = (value) => {
		navigate(`/${value.language}`);
	};
	return (
		<div className="searchPageWrapper">
			<div className="InfoWrapper">
				<div className="searchLogoContainer">
					<img src={logo} alt="logo" className="search-logo" />
					<div className="dropdownContainer">
						<CountryDropdown onCountryChange={handleCountryChange} />
					</div>
				</div>
				<div className="vl"></div>
				<div className="pageInfoContainer">
					<div className="red-banner search-banner">
						{t("HomePageTitleText")}
					</div>
					<div className="pageInfo">
						<p>{t("HomePageContentFirst")}</p>

						<p>{t("HomePageContentMiddle")}</p>

						<p>{t("HomepageContentlast")}</p>
					</div>
				</div>
			</div>
			<SearchableSelect />
		</div>
	);
};
