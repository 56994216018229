import {useEffect, useContext} from "react";
import "../styles/PageNotFound.css";
import NotFoundImage from "../assets/404.svg";
import {useNavigate, useParams} from "react-router-dom";
import {LangContext} from "../Context/lanContext";
import {useTranslation} from "react-i18next";
export const PageNotFound = () => {
	const navigate = useNavigate();
	const {lang} = useParams();
	const {changeLanguage} = useContext(LangContext);
	const {t} = useTranslation();
	useEffect(() => {
		if (lang !== undefined && ["en", "fr"].includes(lang)) {
			changeLanguage(lang);
		} else if (lang === undefined) {
			changeLanguage("en");
		} else {
			navigate("/notfound");
		}
	}, []);

	const navigateToHomeScreen = () => {
		if (lang !== undefined && ["en", "fr"].includes(lang)) {
			navigate(`/${lang}`);
		} else {
			navigate("/");
		}
	};
	return (
		<div className="pageContainer">
			<img
				src={NotFoundImage}
				alt="Page Not Found"
				className="page-not-found-image"
			/>
			<h4 className="headerText">{t("PageNotFound")}</h4>
			<button className="backbutton" onClick={() => navigateToHomeScreen()}>
				{t("GoBack")}
			</button>
		</div>
	);
};
