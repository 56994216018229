export const resources = {
	en: {
		translation: {
			"QualityProduct": "QUALITIES AND ENVIRONMENTAL CHARACTERISTICS OF THE PRODUCT",
			"Recycled Content": "RECYCLED CONTENT",
			"Traceability": "TRACEABILITY",
			"FootwearCountryOfOrigin":"FOOTWEAR COUNTRY OF ORIGIN",
			"TextileProductCountryOfOrigin":"TEXTILE PRODUCT COUNTRY OF ORIGIN",
			"BackToSearch":"Back to Search",
			"TableHeaderData": {
				"STITCHING": "STITCHING",
				"FINISING": "FINISING",
				"MAIN FABRIC": "MAIN FABRIC",
				"DYEING & PRINTING": "DYEING & PRINTING",
				"PRODUCT ASSEMBLY": "PRODUCT ASSEMBLY"
			},
			"HazardousSubstances": "HAZARDOUS SUBSTANCES",
			"HazardousSubstancesDescription":"UA CONNECTED FOOTWEAR PRODUCTS CONTAIN A BATTERY TO CONNECT TO APPS & SERVICES VIA BLUETOOTH. BATTERY HAZARDOUS SUBSTANCES ARE MANGANESE DIOXIDE, LITHIUM, PROPYLENE CARBONATE, 1,2 DIMETHOXY ETHANE, AND LITHIUM PERCHLORATE. NO OTHER PRODUCTS CONTAIN HAZARDOUS SUBSTANCES.",
			"Recyclability":"RECYCLABILITY",
			"Reusability":"REUSABILITY",
			"MicroPlastics":"MICROPLASTICS",

			"PackagingHeader":"QUALITIES AND ENVIRONMENTAL CHARACTERISTICS OF THE PACKAGING",
			"PackagingRecycledContentDescription":"PACKAGING COMPRISES AT LEAST <bold>{{recycledContent}}%</bold> OF RECYCLED MATERIALS.",
			"PackagingHazardousSubstancesDescription":"PACKAGING CONTAINS NO HAZARDOUS SUBSTANCES",
			"EcoRegistrationNumber":"ECO REGISTRATION NUMBER",
			
			"HomePageTitleText": "DIGITAL PRODUCT PASSPORT",
			"HomePageContentFirst": "Welcome to Under Armour’s digital product passport page in accordance with the requirements of the French anti-waste law, AGEC.",
			"HomePageContentMiddle": "To begin your search, enter in the search field below the UA style number as it appears on the site or on the product labeling. You do not need to include the 3-digit color number after the hyphen on the style number.",
			"HomepageContentlast": "The field pre-fills as you enter this information. Select the product you are interested in.",
			"ProductRecycledContent": "PRODUCT COMPRISES AT LEAST <bold>{{recycledContent}}</bold> OF RECYCLED MATERIALS. SEE PRODUCT LABELING FOR FINAL RECYCLED CONTENT.",
			"NotApplicable": "NOT APPLICABLE",
			"ProductMicroPlasticsContent": "THIS PRODUCT RELEASES PLASTIC MICROFIBERS INTO THE ENVIRONMENT DURING WASHING.",
			"SearchPlaceholder": "UA STYLE NUMBER",
			"ProductNotFound":"Product Not Found",
			"PageNotFound":"WE CAN’T FIND THE PAGE!",
			"GoBack":"Go back",
			"RecyclabilityContent":"MOSTLY RECYCLABLE PACKAGING",
			"StyleNumber":"Style Number"

		}
	}
};