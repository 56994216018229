import {useTranslation, Trans} from "react-i18next";
import logo from "../assets/UAlogo.svg";
import "../styles/Product.css";
import {Table} from "../components/Table";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState, useContext} from "react";
import instance from "../config/axiosConfig";
import backToSearchIcon from "../assets/searchIcon.svg";
import {LangContext} from "../Context/lanContext";
import CountryDropdown from "../components/CountryDropdown";

export const Product = () => {
	const {id, lang} = useParams();

	const {t} = useTranslation();

	const navigate = useNavigate();

	const [isTextile, setIsTextile] = useState(true);
	const [tableData, setTableData] = useState([]);
	const [productData, setProductData] = useState({
		styleNumber: "",
		styleName: "",
		microPlastics: "",
		recyclingContent: "",
		totalPackagingRCPercent: "",
		frenchTranslationStyleName: "",
		colorwayContent: "",
		licenseeStyle: "",
	});

	const {changeLanguage} = useContext(LangContext);

	const fetchProduct = async (id) => {
		try {
			const response = await instance.get(`/products/${id}`);
			const data = response.data?.data;
			setProductData({
				styleNumber: data?.styleNumber,
				styleName: data?.styleName,
				microPlastics: data?.microplastics,
				recyclingContent: data?.recyclingContent,
				articleNumber: data?.articleNumber,
				totalPackagingRCPercent: data?.totalPackagingRCPercent,
				frenchTranslationStyleName: data?.frenchTranslationStyleName,
				colorwayContent: data?.colorwayName,
				licenseeStyle: data?.licenseeStyle,
			});

			if (data?.cooDyeing === "" && data?.cooWeaving === "") {
				setIsTextile(false);
				let temp = {
					STITCHING: data.cooStitching,
					FINISING: data.cooFinishing,
					"PRODUCT ASSEMBLY": data.cooAssembly,
				};
				setTableData([temp]);
			} else {
				let temp = {
					"MAIN FABRIC": data.cooWeaving,
					"DYEING & PRINTING": data.cooDyeing,
					"PRODUCT ASSEMBLY": data.cooAssembly,
				};
				setTableData([temp]);
			}
		} catch (err) {
			console.log("NetworkError", err);
			navigate("/notfound");
		}
	};

	useEffect(() => {
		fetchProduct(id);
		if (lang !== undefined && ["en", "fr"].includes(lang)) {
			changeLanguage(lang);
		} else if (lang === undefined) {
			changeLanguage("en");
		} else {
			navigate("/notfound");
		}
	}, [id]);

	const handleCountryChange = (value) => {
		navigate(`/${value?.language}/product/${id}`);
	};
	return productData.styleName && productData.styleNumber ? (
		<div className="backgoundContainer">
			<div className="logoContainer">
				<img src={logo} alt="logo" className="logo" />
			</div>
			<div className="product-details-container">
				<div className="detailsContainer">
					<h2 className="header-margin">
						{lang === "en"
							? productData?.styleName
							: productData?.frenchTranslationStyleName}
					</h2>
					{productData.colorwayContent && (
						<div className="productInfo">
							Colorway:
							<h4 className="header-margin">{productData.colorwayContent}</h4>
						</div>
					)}
					{productData?.articleNumber ? (
						<div className="productInfo">
							<div className="ProductArticle">
								Style#:
								<h4 className="header-margin">{productData.styleNumber}</h4>
							</div>
							<div className="ProductArticle">
								Article#:
								<h4 className="header-margin">{productData?.articleNumber}</h4>
							</div>
						</div>
					) : (
						<div className="productInfo">
							<div className="ProductArticle">
								Style#:
								<h4 className="header-margin">{productData?.licenseeStyle}</h4>
							</div>
							<div className="ProductArticle">
								Alt Style#:
								<h4 className="header-margin">{productData.styleNumber}</h4>
							</div>
						</div>
					)}
				</div>
				<div className="dropdownContainer">
					<CountryDropdown onCountryChange={handleCountryChange} />
				</div>
				<div
					className="desktop-back-to-search"
					onClick={() => navigate(`/${lang}`)}
				>
					<img
						src={backToSearchIcon}
						alt="Back to Search"
						className="pdt-search-icon"
					/>
					<span>{t("BackToSearch")}</span>
				</div>
			</div>
			<div className="banner-container red-banner">{t("QualityProduct")}</div>
			<div className="content-container">
				<div className="recyled-container grid-layout">
					<h4>{t("Recycled Content")}</h4>
					<div className="content-margin">
						<Trans
							i18nKey="ProductRecycledContent"
							values={{recycledContent: productData.recyclingContent}}
							components={{italic: <i />, bold: <strong />}}
						/>
					</div>
				</div>
				<hr className="solid"></hr>
				<div className="tracability-container grid-layout">
					<h4>{t("Traceability")}</h4>
					<div className="content-margin">
						{isTextile ? (
							<>
								<h4 className="tableHeading">
									{t("TextileProductCountryOfOrigin")}
								</h4>
								<Table data={tableData} />
							</>
						) : (
							<>
								<h4 className="tableHeading">{t("FootwearCountryOfOrigin")}</h4>
								<Table data={tableData} />
							</>
						)}
					</div>
				</div>
				<hr className="solid"></hr>
				<div className="hazardous-container grid-layout">
					<h4>{t("HazardousSubstances")}</h4>
					<div className="content-margin">
						{t("HazardousSubstancesDescription")}
					</div>
				</div>
				<hr className="solid"></hr>
				<div className="recyclability-container grid-layout">
					<h4>{t("Recyclability")}</h4>
					<div className="content-margin">{t("NotApplicable")}</div>
				</div>
				<hr className="solid"></hr>
				<div className="microplastics-container grid-layout">
					<h4>{t("MicroPlastics")}</h4>
					<div className="content-margin">
						{productData.microPlastics
							? t("ProductMicroPlasticsContent")
							: t("NotApplicable")}
					</div>
				</div>
			</div>
			<div className="banner-container red-banner">{t("PackagingHeader")}</div>
			<div className="content-container">
				<div className="recyled-container grid-layout">
					<h4>{t("Recycled Content")}</h4>
					<div className="content-margin">
						<Trans
							i18nKey="PackagingRecycledContentDescription"
							values={{recycledContent: productData.totalPackagingRCPercent}}
							components={{italic: <i />, bold: <strong />}}
						/>
					</div>
				</div>
				<hr className="solid"></hr>
				<div className="recyclability-container grid-layout">
					<h4>{t("Recyclability")}</h4>
					<div className="content-margin">{t("RecyclabilityContent")}</div>
				</div>
				<hr className="solid"></hr>
				<div className="reusabilty-container grid-layout">
					<h4>{t("Reusability")}</h4>
					<div className="content-margin">{t("NotApplicable")}</div>
				</div>
				<hr className="solid"></hr>
				<div className="hazardous-container grid-layout">
					<h4>{t("HazardousSubstances")}</h4>
					<div className="content-margin">
						{t("PackagingHazardousSubstancesDescription")}
					</div>
				</div>
			</div>
		</div>
	) : (
		""
	);
};
