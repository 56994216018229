import {useState, useEffect, useContext} from "react";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import searchIcon from "../assets/searchIcon.svg";
import "../styles/SearchableSelect.css";
import instance from "../config/axiosConfig";
import {useDebounce} from "../hooks/useDebounce";
import {LangContext} from "../Context/lanContext";
import {useTranslation} from "react-i18next";
export default function SearchableSelect() {
	const [options, setOptions] = useState([]);
	const {lang} = useContext(LangContext);
	const [menuPlacement, setMenuPlacement] = useState("bottom");
	const [input, setInput] = useState("");
	const debouncedValue = useDebounce(input, 300);
	const [loading, setLoading] = useState(false);
	const {t} = useTranslation();
	const cleanedText = debouncedValue.replace(/[^a-zA-Z0-9À-ÿœéèêëô]/g, "");
	useEffect(() => {
		const handleScreenResize = () => {
			if (window.innerWidth < 500) {
				setMenuPlacement("top");
			} else {
				setMenuPlacement("bottom");
			}
		};

		window.addEventListener("resize", handleScreenResize);

		handleScreenResize();

		return () => {
			window.removeEventListener("resize", handleScreenResize);
		};
	}, []);

	const navigate = useNavigate();

	const fetchSearchResults = async (value) => {
		try {
			const response = await instance.post("/products/search", {
				searchTerm: value,
				locale: lang.toUpperCase(),
			});
			const results = response?.data;
			if (Array.isArray(results?.data)) {
				const response = results?.data?.map((item) => {
					return {
						value: item.key,
						label: item.value,
					};
				});
				setOptions(response);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.log("error");
		}
	};

	useEffect(() => {
		if (cleanedText !== "" && cleanedText.length >= 3) {
			setLoading(true);
			fetchSearchResults(cleanedText);
		}
	}, [debouncedValue]);

	const formatOptionLabel = ({label}, {inputValue}) => {
		if (!inputValue) return label;

		const regex = new RegExp(`(${inputValue})`, "i");
		const parts = label.split(regex);
		return parts.map((part, index) =>
			regex.test(part) ? <strong key={index}>{part}</strong> : part
		);
	};
	const customStyles = {
		menuList: (base) => ({
			...base,
			maxHeight: "150px",
			fontSize: "13px",
			"::-webkit-scrollbar": {
				width: "4px",
				height: "0px",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"::-webkit-scrollbar-thumb": {
				background: "#888",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
		option: (provided) => ({
			...provided,
			outline: "none", // Remove the blue outline when selected
		}),
		control: (base) => ({
			...base,
			border: 0,
			boxShadow: "none",
			borderRadius: "10px", // Remove the border
			minWidth: 200, // Adjust the width as needed
			backgroundColor: "#F2F2F2", // Change the background color here
			fontSize: "16px",
			paddingBlock: "12px",
			width: "100%",
			outline: "none",
		}),
	};

	const handleSelected = ({value}) => {
		navigate(`/${lang}/product/${value}`);
	};

	const customFilter = ({label}, searchString) => {
		const normalizedLabel = label
			.replace(/[^a-zA-Z0-9À-ÿœéèêëô]/g, "")
			.toLowerCase();
		const normalizedSearch = searchString
			.replace(/[^a-zA-Z0-9À-ÿœéèêëô]/g, "")
			.toLowerCase();
		return normalizedLabel.includes(normalizedSearch);
	};

	return (
		<div className="selectBox">
			<img src={searchIcon} alt="logo" className="search-icon-absolute" />
			<Select
				defaultValue={""}
				inputValue={input}
				onChange={handleSelected}
				onInputChange={(value, action) => {
					// only set the input when the action that caused the change equals to "input-change"
					if (action.action === "input-change") {
						setInput(value);
					}
				}}
				options={options}
				components={{
					IndicatorSeparator: null,
					DropdownIndicator: null,
				}}
				styles={customStyles}
				formatOptionLabel={formatOptionLabel}
				className="custom-select"
				placeholder={
					<span className="placeholder">{t("SearchPlaceholder")} </span>
				}
				menuPlacement={menuPlacement}
				noOptionsMessage={() =>
					input && cleanedText.length >= 3 ? t("ProductNotFound") : null
				}
				loadingMessage={() => null}
				isLoading={loading}
				filterOption={customFilter}
				menuIsOpen={input}
			/>
		</div>
	);
}
