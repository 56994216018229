export const resources = {
	fr: {
		translation: {
			"QualityProduct": "QUALITÉS ET CARACTÉRISTIQUES ENVIRONNEMENTALES DU PRODUIT",
			"Recycled Content": "CONTENU RECYCLÉ",
			"Traceability": "TRAÇABILITÉ",
			"FootwearCountryOfOrigin":"PAYS D'ORIGINE DES CHAUSSURES",
			"TextileProductCountryOfOrigin":"PAYS D'ORIGINE DU PRODUIT TEXTILE",
			"BackToSearch":"Retour à la recherche",
			"TableHeaderData": {
				"STITCHING": "COUTURES",
				"FINISING": "FINITIONS",
				"MAIN FABRIC": "TISSU PRINCIPAL",
				"DYEING & PRINTING": "TEINTURE ET IMPRESSION",
				"PRODUCT ASSEMBLY": "ASSEMBLAGE DU PRODUIT"
			},
			"HazardousSubstances": "SUBSTANCES DANGEREUSES",
			"HazardousSubstancesDescription":"LES CHAUSSURES CONNECTÉES UNDER ARMOUR CONTIENNENT UNE BATTERIE POUR SE CONNECTER AUX APPLICATIONS ET SERVICES VIA BLUETOOTH. LES SUBSTANCES DANGEREUSES CONTENUES DANS LA BATTERIE SONT LE DIOXYDE DE MANGANÈSE, LE LITHIUM, LE CARBONATE DE PROPYLÈNE, LE 1,2-DIMÉTHOXYÉTHANE ET LE PERCHLORATE DE LITHIUM. AUCUN AUTRE PRODUIT NE CONTIENT DE SUBSTANCES DANGEREUSES.",
			"Recyclability":"RECYCLABITÉ",
			"Reusability":"RÉUTILISABILITÉ",
			"MicroPlastics":"MICROPLASTIQUES",
			"PackagingHeader":"QUALITÉS ET CARACTÉRISTIQUES ENVIRONNEMENTALES DE L'EMBALLAGE",
			"PackagingRecycledContentDescription":"EMBALLAGE COMPORTANT AU MOINS <bold>{{recycledContent}}%</bold> DE MATIÈRES RECYCLÉES",

			"PackagingHazardousSubstancesDescription":"L'EMBALLAGE NE CONTIENT AUCUNE SUBSTANCE DANGEREUSE",

			"EcoRegistrationNumber":"NUMÉRO D'ENREGISTREMENT ÉCO",

			"HomePageTitleText":"PASSEPORT PRODUIT NUMÉRIQUE",
			"HomePageContentFirst":"Bienvenue sur la page du passeport numérique des produits Under Armour, conformément aux exigences de la loi française AGEC relative à l'anti-gaspillage.",
			"HomePageContentMiddle":"Pour lancer une recherche, saisis dans le champ ci-dessous le numéro du modèle Under Armour tel qu'il apparaît sur le site ou sur l'étiquette du produit. Il n'est pas nécessaire d'inclure le numéro de couleur à trois chiffres situé après le trait d'union dans le numéro du modèle.",
			"HomepageContentlast":"Le champ est prérempli au fur et à mesure que tu saisis ces informations. Sélectionne le produit qui t'intéresse.",
			"ProductRecycledContent":"PRODUIT COMPORTANT AU MOINS <bold>{{recycledContent}}</bold> DE MATIÈRES RECYCLÉES. VOIR L'ÉTIQUETTE DU PRODUIT POUR CONNAÎTRE LE CONTENU RECYCLÉ FINAL.",
			"NotApplicable":"NON APPLICABLE",
			"ProductMicroPlasticsContent":"CE PRODUIT LIBÈRE DES MICROFIBRES PLASTIQUES DANS L'ENVIRONNEMENT PENDANT LE LAVAGE",
			"SearchPlaceholder": "NUMÉRO DE STYLE UA",
			"ProductNotFound":"Produit non trouvé",
			"PageNotFound":"NOUS NE TROUVONS PAS LA PAGE !",
			"GoBack":"Retourner",
			"RecyclabilityContent":"EMBALLAGE EN GRANDE PARTIE RECYCLABLE",
			"StyleNumber":"Numéro du modèle"

		}
	}
};