import {useEffect, useContext} from "react";
import {useTranslation} from "react-i18next";
import {LangContext} from "../Context/lanContext";
import PropTypes from "prop-types";
const Table = (props) => {
	const {data} = props;
	const {t, i18n} = useTranslation();
	const {lang} = useContext(LangContext);

	useEffect(() => {
		const dynamicDataKeys = data.reduce((acc, item) => {
			Object.keys(item).forEach((key) => {
				if (!acc.includes(key)) {
					acc.push(key);
				}
			});
			return acc;
		}, []);

		const dynamicDataTranslations = dynamicDataKeys.reduce((acc, key) => {
			acc[key] = t(`TableHeaderData.${key}`);
			return acc;
		}, {});

		i18n.addResourceBundle(
			"en",
			"TableHeaderData",
			{TableHeaderData: dynamicDataTranslations},
			true,
			true
		);
		i18n.addResourceBundle(
			"fr",
			"TableHeaderData",
			{TableHeaderData: dynamicDataTranslations},
			true,
			true
		);
	}, [data, lang]);

	return (
		<table className="customTable">
			<thead>
				<tr>
					{Object.keys(data[0]).map((key) => (
						<th key={key}>{t(`TableHeaderData.${key}`)}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{data.map((item, index) => (
					<tr key={index}>
						{data.length > 0 &&
							Object.values(item).map((value, vIndex) => (
								<td key={vIndex}>{value}</td>
							))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

Table.propTypes = {
	data: PropTypes.array,
};

export {Table};
