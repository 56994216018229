import PropTypes from "prop-types";
import {createContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
const LangContext = createContext();

const LangProvider = ({children}) => {
	const [lang, setLang] = useState("en");
	const {i18n} = useTranslation();
	const changeLanguage = (newLanguage) => {
		setLang(newLanguage);
	};

	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	return (
		<LangContext.Provider value={{lang, changeLanguage}}>
			{children}
		</LangContext.Provider>
	);
};

LangProvider.propTypes = {
	children: PropTypes.object,
};

export {LangProvider, LangContext};
