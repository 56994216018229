/* eslint-disable no-undef */

import { Route, Routes } from "react-router-dom";
import { Search } from "./pages/Search";
import { Product } from "./pages/Product";
import { PageNotFound } from "./pages/PageNotFound";
import { datadogRum } from "@datadog/browser-rum";


if (process.env.REACT_APP_DD_ENABLED==="true") {
	datadogRum.init({
		applicationId: process.env.REACT_APP_DD_APP_ID,
		clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
		site: "datadoghq.com",
		service: "french-sustainability-app",
		env:process.env.REACT_APP_ENV,
		// Specify a version number to identify the deployed version of your application in Datadog 
		// version: '1.0.0', 
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: "mask-user-input",
	});
}

function App() {
	return <Routes>
		<Route path="/" Component={Search} />
		<Route path="/product/:id" Component={Product} />
		<Route path="/:lang" Component={Search} />
		<Route path="/:lang/product/:id" Component={Product} />
		<Route path="/:lang/notfound" Component={PageNotFound} />
		<Route path="/notfound" Component={PageNotFound} />
		<Route path="/:lang/*" Component={PageNotFound} />
		<Route path="*" Component={PageNotFound} />
	</Routes>;
}

export default App;
